// FwLink URLs
export const MicrosoftSupportDomain: string = "https://go.microsoft.com.office.test2-off365.provisionsoftwaredivisionsrl.myshn.net";

export const AccountSecurityInfoLearnMore: string = `${MicrosoftSupportDomain}/fwlink/?LinkId=262032`;
export const MicrosoftChallengeBlockLearnMore: string = `${MicrosoftSupportDomain}/fwlink/?linkid=2237450`;
export const MicrosoftPrivacyFwLinkUrl: string = `${MicrosoftSupportDomain}/fwlink/?LinkId=521839`;
export const PrivacyAndCookiesUrl: string = `${MicrosoftSupportDomain}/fwlink/?LinkID=248681`;
export const PrivateBrowsingLearnMoreUrl: string = `${MicrosoftSupportDomain}/fwlink/?LinkID=2281649`;

// Other
export const MicrosoftConsumerHelpBaseUrl: string = "aka.ms.office.test2-off365.provisionsoftwaredivisionsrl.myshn.net/sign-in-support";
export const MicrosoftConsumerHelpHrefUrl: string = `https://${MicrosoftConsumerHelpBaseUrl}`;
